export const environment = {
    base_Url: 'http://daltex.drmonkey.co/data/',
     //base_Url1: 'https://honesolutionsapp.herokuapp.com'
  //base_Url1: 'http://localhost:8080'
    //produccion
    base_Url1: 'https://honesolutions.ue.r.appspot.com'
       // pruebas
   // base_Url1: 'https://honesolutions.herokuapp.com'
    // base_Url: 'https://fprestacionalwstst.azurewebsites.net/'
};
